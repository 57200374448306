@import url(https://fonts.googleapis.com/css?family=Dancing+Script:700);
@import url(https://brick.a.ssl.fastly.net/Roboto:400,700);
@import "resources/assets/sass/wise-variables";
@import "bower_components/bootswatch/sandstone/variables";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap";
@import "bower_components/bootswatch/sandstone/bootswatch";

@include text-emphasis-variant('.text-complete', $state-complete-text);
.panel-complete {
  @include panel-variant($panel-complete-border, $panel-complete-text, $panel-complete-heading-bg, $panel-complete-border);
}

.content {
  padding: 110px 15px 0;
}

.flash {
  margin-top: 90px;
  padding: 0 15px 0;
  margin-bottom: -120px;
}

.navbar-brand > img {
  display: inline;
  max-width: 100px;
  position: relative;
  top: -23px;
}

.special-field {
  display:none;
}

nav {
  -webkit-font-smoothing: subpixel-antialiased;
}

.navbar-inverse .navbar-nav > li > a {
  color: #fff;
}

.navbar-inverse .navbar-nav > li > a:hover, .navbar-inverse .navbar-nav > li > a:focus {
  color: #f1325d;
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
  background-color: #f1325d;
  color: #fff;
}

.dropdown-menu > li > a {
  color: #fff;
}

.dropdown-menu {
  background-color: #69091F;
  border: 0;
}

.tooltip {
  text-transform: none !important;
}

.cover h1, .cover h2 {
  background: rgba(255, 255, 255, 0.75);
  padding: 4px 8px;
  color: black;
  text-align: center;
}

.cover h1 {
  display: block;
}

.thumbnail img {
  border-radius: 3px;
}

.video-list-item {
  counter-increment: a;
}

.video-list-item:hover {
  background-color: #F8F5F0;
}

.video-list-item:before {
  font-size: 10px;
  font-weight: 700;
  background: #C94056;
  color: #fff;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  content: counter(a);
  margin-right: 25px;
  position: relative;
  display: inline-block
}
@media (min-width : 480px) {
  .module-purchase {
    padding: 25px;
    font-size: 22px !important;
  }
}
@media (max-width: 479px) {
  .module-purchase {
    padding: 25px;
    font-size: 5vw !important;
  }
}

/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
  height: 350px;
  margin-top: 60px;
  margin-bottom: -60px;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel .item {
  height: 350px;
  background-color: #777;
}
.carousel-inner > .item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 350px;
  width: auto;
}

/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 20px;
  text-align: center;
}
.marketing h2 {
  font-weight: normal;
}
.marketing .col-lg-4 p {
  margin-right: 10px;
  margin-left: 10px;
}


/* Featurettes
------------------------- */

.featurette-divider {
  margin: 80px 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -1px;
}

.featurette-image {
  max-width: 350px;
}

.v-align {
  float: none;
  display: inline-block;
  vertical-align: middle;
  margin-top: 20px;
}

.grayscale {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android */
  -webkit-filter: grayscale(100%) opacity(50%);
  filter: grayscale(100%) opacity(50%);
  filter: gray; /* IE 6-9 */
  -webkit-transition: all .6s ease; /* Fade to color for Chrome and Safari */
  -webkit-backface-visibility: hidden; /* Fix for transition flickering */
}

.grayscale:hover {
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  filter: none;
}

.nav-sidebar {
  width: 100%;
  padding: 8px 0;
  border-right: 1px solid #ddd;
}
.nav-sidebar a {
  color: $text-color;
  -webkit-transition: all 0.08s linear;
  -moz-transition: all 0.08s linear;
  -o-transition: all 0.08s linear;
  transition: all 0.08s linear;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
  margin-top: 3px;
}
.nav-sidebar .active a {
  cursor: default;
  background-color: $brand-success;
  color: #fff;
  text-shadow: 1px 1px 1px #666;
}
.nav-sidebar .active a:hover {
  background-color: $brand-success;
}
.nav-sidebar .text-overflow a,
.nav-sidebar .text-overflow .media-body {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
}
.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content:" ";
  width: 3px;
  background-color: #eeeeee;
  left: 50%;
  margin-left: -1.5px;
}
.timeline > li {
  margin-bottom: 20px;
  position: relative;
}
.timeline > li:before, .timeline > li:after {
  content:" ";
  display: table;
}
.timeline > li:after {
  clear: both;
}
.timeline > li:before, .timeline > li:after {
  content:" ";
  display: table;
}
.timeline > li:after {
  clear: both;
}
.timeline > li > .timeline-panel {
  width: 46%;
  float: left;
  border: 1px solid #d4d4d4;
  border-radius: 2px;
  padding: 20px;
  position: relative;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
}
li > .muted {
  background-color: #f3f4f7;
}
li > .muted:after{
  border-left-color: #f3f4f7 !important;
}
li.timeline-inverted > .muted:after{
  border-right-color: #f3f4f7 !important;
}
.timeline > li > .timeline-panel:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #ccc;
  border-right: 0 solid #ccc;
  border-bottom: 15px solid transparent;
  content:" ";
}
.timeline > li > .timeline-panel:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #fff;
  border-bottom: 14px solid transparent;
  content:" ";
}
.timeline > li > .timeline-badge {
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 16px;
  left: 50%;
  margin-left: -25px;
  background-color: #999999;
  z-index: 100;
  border-radius: 50%;
}
.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}
.timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}
.timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}
.timeline-badge.primary {
  background-color: $brand-primary !important;
}
.timeline-badge.success {
  background-color: $brand-success !important;
}
.timeline-badge.warning {
  background-color: $brand-warning !important;
}
.timeline-badge.danger {
  background-color: $brand-danger !important;
}
.timeline-badge.info {
  background-color: $brand-info !important;
}
.timeline-title {
  margin-top: 0;
  color: inherit;
}
.timeline-body > p, .timeline-body > ul {
  margin-bottom: 0;
}
.timeline-body > p + p {
  margin-top: 5px;
}
@media (max-width: 767px) {
  ul.timeline:before {
    left: 40px;
  }
  ul.timeline > li > .timeline-panel {
    width: calc(100% - 90px);
    width: -moz-calc(100% - 90px);
    width: -webkit-calc(100% - 90px);
  }
  ul.timeline > li > .timeline-badge {
    left: 15px;
    margin-left: 0;
    top: 16px;
  }
  ul.timeline > li > .timeline-panel {
    float: right;
  }
  ul.timeline > li > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }
  ul.timeline > li > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
}

tr.clickable-row { cursor: pointer; }

.table-vcenter td {
  vertical-align: middle!important;
}

.btn-white {
  color: #1c2a3f;
  background-color: #fff;
  border: 1px solid #DFD7CA;
  border-radius: 4px;
  &:hover {
    color: #1c2a3f;
    background-color: #fff;
    border: 1px solid #DFD7CA;
    border-radius: 4px;
  }
  .filter-option {
    color: #1c2a3f;
    font-size: 16px;
    text-transform: none !important;
  }
}

.home-page-product {
  text-align: center;
  background-color: #F8F5F0;
  border-radius: 6px;
  overflow: hidden;
}

.home-page-product ul {
  list-style: none;
  margin: 0;
  padding-left: 0px;
}

.home-page-product ul li {
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
}

.home-page-product ul li.price {
  background-color: $brand-success;
  font-size: 26px;
  color: #fff;
  text-align: center;
  padding-right: 5px;
  padding-left: 5px;
}

.home-page-product ul li.price img {
  display: block;
  margin: auto;
  margin-bottom: 10px;
}

.home-page-product .pricing-footer {
  padding: 15px;
}

.db-pricing-seven {
  margin-bottom: 30px;
  margin-top: 30px;
  text-align: center;
  background-color: #F8F5F0;
  -moz-transition: margin-top 0.25s ease-out;
  -ms-transition: margin-top 0.25s ease-out;
  transition: margin-top 0.25s ease-out;
  border-radius: 6px;
  overflow: hidden;
}

.db-pricing-seven:hover {
  margin-top: 5px;
}

.db-pricing-seven ul {
  list-style: none;
  margin: 0;
  text-align: center;
  padding-left: 0px;
}

.db-pricing-seven ul li {
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
  text-transform: uppercase;
}

.db-pricing-seven ul li.price {
  background-color: $brand-success;
  padding: 40px 20px 20px 20px;
  font-size: 20px;
  font-weight: 900;
  color: #fff;
}

.db-pricing-seven ul li.price small {
  font-weight: 300;
  color: #f0f0f0;
}

.db-pricing-seven ul li.price i {
  padding: 30px;
  font-size: 50px;
  display: block;
}


.db-pricing-seven .pricing-footer {
  padding: 20px;
}

.db-pricing-seven .pricing-footer a i {
  margin: 5px;
  top: 3px;
}

input.fa-check-round {
  visibility: hidden;
}

input.fa-check-round:checked:after, input.fa-check-round:after {
  visibility: visible;
  font-family: FontAwesome;
  font-size:50px;
  top: -16px;
  left: 20px;
  position: relative;
}

input.fa-check-round:checked:after {
  content: '\f058';
  color: #93c54b;
}

input.fa-check-round:after {
  content: '\f10c';
  color: #f0f0f0;
}

.subscription-upsell {
  top: 12px;
  left: -40px;
  position: relative;
}

.subscription-total {
  font-size: 100% !important;
}

.list-group-horizontal {
  margin-bottom: 0;
  margin-top: 10px;
}

.list-group-horizontal .list-group-item {
  display: inline-block;
}
.list-group-horizontal .list-group-item {
  margin-bottom: 0;
  margin-left:-4px;
  margin-right: 0;
}
.list-group-horizontal .list-group-item:first-child {
  border-top-right-radius:0;
  border-bottom-left-radius:4px;
}
.list-group-horizontal .list-group-item:last-child {
  border-top-right-radius:4px;
  border-bottom-left-radius:0;
}

.no-underline:hover {
  text-decoration: none;
}

.post-title {
  margin-bottom: 0;
}

.post-date {
  margin-top: 0;
}

.post-separator {
  margin-top: 45px;
  margin-bottom: 25px;
}

.no-margins {
  margin: 0;
}

/* Braintree Hosted Fields styling classes*/
//----- GENERAL

.sub-body {
  background: #FFFFFF;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  overflow: hidden;
  transition: all 0.2s;
  //width: 540px;
}

.pay-select {
  background: #F3F3F3;
  display: flex;
  font-size: 13px;
  padding: 24px 0;
  text-align: center;
  justify-content: space-around;

  .pay-select--card,
  .pay-select--paypal {
    position: relative;
  }

  .is-active:after {
    border-bottom: 10px solid #ffffff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    bottom: -24px;
    content: ' ';
    height: 0;
    left: 50%;
    margin-left: -5px;
    position: absolute;
    width: 0;
  }

  p {
    margin: 0;
  }
}

.pay-select__item {
  width: 48%;

  &:hover {
    cursor: pointer;
  }
}

.separator {
  background: #BABABA;
  height: 60px;
  width: 1px;
}
//----- Form body

.select-body {
  padding: 32px;
  text-align: center;
  background-color: #fff;
}

.select-body--paypal {
  text-align: center;
}
//----- Form

.select-body__content {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transform: translateY(30px);
  transition: visibility 0.3s, opacity 0.3s ease, height 0.3s ease 0.3s, transform 0.2s ease 0.2s;
  visibility: hidden;

  &.is-active {
    height: 260px;
    opacity: 1;
    transform: none;
    transition: visibility 0.3s, opacity 0.3s ease, height 0.3s ease 0.3s, transform 0.2s ease;
    visibility: visible;
  }

  &.is-active.select-body--paypal {
    height: 80px;
  }
}

.blockquote-about {
  padding: 11px 22px;
  margin: 0 0 22px;
  font-size: 20px;
  background-color: $brand-success;
  color: #fff;
  border-radius: 5px;
  border: 0;
}

.footer-about {
  font-weight: 800;
  color: #fff;
}

@media (min-width: 1000px) {
  .vcenter-row {
    display: table;
  }
  .vcenter {
    display: table-cell;
    vertical-align: middle;
    float: none;
  }
}

.panel-title {
  font-size: 16px !important;
  font-weight: bold !important;
  text-transform: none !important;
}

.filter-option {
  color: #fff;
}

@media (min-width: 992px) {
  .club-image {
    top:150px;
    position: relative;
  }
}

.label-full-width {
  display: inline-block !important;
  padding: 0.5em 0 0.5em 0;
  width: 100%
}

.datepicker {
  background-color: #fff;
}