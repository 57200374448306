//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base:              #000 !default;
$gray-darker:            #1F497D !default;
$gray-dark:              #8E8C84 !default;
$gray:                   #98978B !default;
$gray-light:             #DFD7CA !default;
$gray-lighter:           #F8F5F0 !default;

$brand-primary:         #29ABE0 !default;
$brand-success:         #94043A !default;
$brand-info:            #1F497D !default;
$brand-warning:         #F47C3C !default;
$brand-danger:          #d9534f !default;
$brand-complete:        #93c54b !default;

$state-complete-text:             $brand-complete !default;
$state-complete-bg:               #d6e9c6 !default;
$state-complete-border:           darken(adjust-hue($state-complete-bg, -10), 5%) !default;

//** Complete color
$label-complete-bg:             $brand-complete !default;

$alert-complete-bg:             $brand-complete !default;
$alert-complete-text:           #fff !default;
$alert-complete-border:         transparent !default;

$panel-complete-text:           $state-complete-text !default;
$panel-complete-border:         $state-complete-border !default;
$panel-complete-heading-bg:     $brand-complete !default;

$btn-complete-color:               #fff !default;
$btn-complete-bg:                  $brand-complete !default;
$btn-complete-border:              transparent !default;

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               #fff !default;
//** Global text color on `<body>`.
$text-color: #1c2a3f !default;

//** Global textual link color.
$link-color:            $brand-success !default;
//** Link hover color set via `darken()` function.
$link-hover-color:      darken($link-color, 10%) !default;
//** Link hover decoration.
$link-hover-decoration: underline !default;


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-serif:       Georgia, "Times New Roman", Times, serif !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base:        $font-family-sans-serif !default;

$font-size-base:          16px !default;
$font-size-large:         ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small:         ceil(($font-size-base * 0.85)) !default; // ~12px

$font-size-h1:            floor(($font-size-base * 2.6)) !default; // ~36px
$font-size-h2:            floor(($font-size-base * 2.15)) !default; // ~30px
$font-size-h3:            ceil(($font-size-base * 1.7)) !default; // ~24px
$font-size-h4:            ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-h5:            $font-size-base !default;
$font-size-h6:            ceil(($font-size-base * 0.85)) !default; // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.428571429 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)) !default; // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family:    inherit !default;
$headings-font-weight:    400 !default;
$headings-line-height:    1.1 !default;
$headings-color:          inherit !default;


//=== Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color:                      $gray-light !default;
$navbar-inverse-bg:                         $brand-success !default;
$navbar-inverse-border:                     $brand-success !default;

// Inverted navbar links
$navbar-inverse-link-color:                 lighten($brand-success, 35%) !default;
$navbar-inverse-link-hover-color:           #fff !default;
$navbar-inverse-link-hover-bg:              transparent !default;
$navbar-inverse-link-active-color:          $navbar-inverse-link-hover-color !default;
$navbar-inverse-link-active-bg:             darken($navbar-inverse-bg, 4%) !default;
$navbar-inverse-link-disabled-color:        #444 !default;
$navbar-inverse-link-disabled-bg:           transparent !default;

// Inverted navbar brand label
$navbar-inverse-brand-color:                #fff !default;
$navbar-inverse-brand-hover-color:          #fff !default;
$navbar-inverse-brand-hover-bg:             transparent !default;

// Inverted navbar toggle
$navbar-inverse-toggle-hover-bg:            $navbar-inverse-link-active-bg !default;
$navbar-inverse-toggle-icon-bar-bg:         $navbar-inverse-link-color !default;
$navbar-inverse-toggle-border-color:        transparent !default;


$navbar-height: 80px;
$grid-float-breakpoint: 992px !default;
$grid-float-breakpoint-max: 991px !default;